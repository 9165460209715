<template>
  <t-modal
    :name="`rental-deliver`"
    :variant="`confirmation`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="!isLoading"
    :clickToClose="!isLoading"
    @closed="onClose"
  >
    <section class="px-4 pt-4 text-2xl font-extrabold text-center">
      Mark Rental as Delivered
    </section>

    <div class="px-5 pt-5">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(onConfirm())"
          enctype="multipart/form-data"
        >
          <span
            >Do you really want to mark this rental record as delivered?</span
          >
          <button type="submit" ref="submitButtonRef" class="hidden">
            Confirm
          </button>
        </form>
      </ValidationObserver>
    </div>

    <section
      class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
    >
      <AppButton
        :disabled="isLoading"
        :variant="`secondary`"
        :class="`w-1/2`"
        @click="onClose"
      >
        No
      </AppButton>

      <AppButton
        :variant="`primary`"
        :class="`w-1/2`"
        :isLoading="isLoading"
        :disabled="isLoading"
        @click="$refs.submitButtonRef.click()"
      >
        Yes
      </AppButton>
    </section>
  </t-modal>
</template>

<script>
import { xMan } from '@/utils'
import { useEndpoints } from '@/composables'

export default {
  props: {
    rentId: {
      type: String,
      required: true,
    },
  },
  components: {},
  mounted() {
    this.$modal.show('rental-deliver')
  },
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    onClose(shouldUpdate) {
      this.$emit('close', shouldUpdate)
    },
    onConfirm() {
      const self = this
      this.isLoading = true

      const formDataProxy = {
        status: 'DELIVERED',
      }

      const formData = new xMan(formDataProxy).toFormData()

      this.$http
        .patch(useEndpoints.vehicleRental.update(this.rentId), formData)
        .then((res) => {
          console.log(res)

          self.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: `Rental Request Delivered`,
              text: `The rental request has been marked as delivered`,
            },
            5000
          )
          self.$store.dispatch('fsTable/fetchData')
          self.onClose(true)
        })
        .catch((err) => {
          console.log('onConfirm', err, err.response)
          self.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Rental Request Update Error`,
              text: `Rental request could not be delivered`,
            },
            5000
          )
          this.isLoading = false
        })
    },
  },
}
</script>
